import { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Training from "./pages/Training";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import NotFound from "./pages/NotFound";
import EventHub from "./pages/Eventhub";
import EventsId from "./pages/events/id";
import Admin from "./pages/Admin";
import Register from "./pages/Accounts/Register";
import Login from "./pages/Accounts/Login";
import Layout from "./Components/Layout";
import RequireAuth from "./Components/RequireAuth";
import Cookiebox from "./Components/Cookiebox";
import SelectUserOrEventhub from "./pages/Accounts/SelectUserOrEventhub";
import Account from "./pages/Accounts/Account";

function App() {

  const [ consentToCookies, setConsentToCookies ] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home cookieConsent={consentToCookies} />} />
        <Route path="training" element={<Training />} />
        <Route path="about" element={<About />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="contact" element={<Contact />} />
        <Route path="impressum" element={<Impressum />} />
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />

        <Route element={<RequireAuth allowedRoles={[5000, 9999]} />}>
          <Route path="eventhub" element={<EventHub />} />
          <Route path="select-user-or-eventhub" element={<SelectUserOrEventhub />} />
          <Route path="account" element={<Account />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[9999]} />}>
          <Route path="events/:id" element={<EventsId />} />
          <Route path="admin" element={<Admin />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
    <Cookiebox setCookieConsent={setConsentToCookies}/>
    </>
  );
}

export default App;
