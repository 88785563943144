import styles from './styles/Admin.module.css'
import Eventsender from '../Components/Eventsender'
import { useState, useEffect } from 'react';
import axios from 'axios';
import AdminEvent from '../Components/AdminEvent';

function Admin() {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);

    const fetchEvents = () => {
        setIsLoading(true); // Ensure loading state is shown during fetch
        axios.get("/api/events/events/")
            .then((res) => {
                setIsLoading(false);
                setEvents(res.data);
            })
            .catch((err) => {
                setIsLoading(false); // Ensure loading state is hidden on error
                console.log(err);
            });
    };

    useEffect(() => {
        fetchEvents(); // Correctly placed to fetch events on component mount
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <div className={styles.horizontal}>
            <div className={styles.entry}>
                <Eventsender onEventCreated={fetchEvents} />
            </div>
            {isLoading ? (
                <div className={styles.container}>
                    <h1 className={styles.ueberschrift}>Lade Events...</h1>
                </div>
            ) : (
                <div className={styles.events}>
                    {events.length === 0 ? (
                        <h2 className={styles.ueberschrift}>Keine Events vorhanden!</h2>
                    ) : (
                        events.map((event) => (
                            <AdminEvent
                                key={event._id} // Added key for list rendering
                                title={event.title}
                                date={new Date(event.date).toLocaleDateString()}
                                time={event.time}
                                duration={event.duration}
                                takenSeats={event.takenSeats}
                                totalSeats={event.totalSeats}
                                id={event._id}
                            />
                        ))
                    )}
                </div>
            )}
        </div>
    )
}

export default Admin