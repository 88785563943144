import React, { useState, useContext } from 'react';
import AuthContext from "../../context/AuthProvider";
import axios from 'axios';
import styles from "../styles/Account.module.css";
import Helmet from 'react-helmet';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_-]).{8,24}$/;

function Account() {
  const { auth } = useContext(AuthContext);
  const name = auth.fullName;
  const email = auth.email;
  const pwd = auth.pwd;
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validPwd, setValidPwd] = useState(true);
  const [deletePassword, setDeletePassword] = useState(""); // New state variable for delete password

  const handleChangePassword = (e) => {
    e.preventDefault();
    const currentPassword = e.target[0].value;
    setNewPassword(e.target[1].value);
    setConfirmNewPassword(e.target[2].value);
    if (newPassword !== confirmNewPassword) {
      alert('Passwörter stimmen nicht überein');
      return;
    }
    if (pwd === newPassword) {
      alert('Neues Passwort darf nicht gleich dem alten Passwort sein');
      return;
    }
    // Check if the new password meets the requirements
    if (!PWD_REGEX.test(newPassword)) {
      setValidPwd(false);
      return;
    }
    axios.put("/api/user/user", { email, currentPassword, newPwd: newPassword, id: auth.id })
      .then((response) => {
        console.log(response.data);
        alert('Passwort erfolgreich geändert');
        window.location.reload(); 
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error:", error.response.data);
          alert(error.response.data);
        } else {
          console.error("Error:", error);
          alert('Fehler beim Ändern des Passworts');
        }
      });
  };

  const handleDeleteAccount = (e) => {
    e.preventDefault(); // Prevent form submission
    const password = deletePassword; // Use state variable instead of prompt
    if (!password) return;

    console.log(email, password);
  
    axios.delete("/api/user/user", {headers: { email, pwd: password, id: auth.id }})
      .then((response) => {
        console.log(response.data);
        alert('Account erfolgreich gelöscht');
        window.location.reload(); 
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error:", error.response.data);
          alert(error.response.data);
        } else {
          console.error("Error:", error);
          alert('Fehler beim Löschen des Accounts');
        }
      });
  };

  return (
    <>
    <Helmet>
      <title>Account | Cold Creek Ranch</title>
      <meta name="description" content="Cold Creek Ranch - Account" />
    </Helmet>
    <div>
      <h2>Accountinformationen</h2>
      <p><strong>Name:</strong> {name}</p>
      <p><strong>Email:</strong> {email}</p>
      
      <h3>Passwort ändern</h3>
      <form onSubmit={handleChangePassword}>
        <input
          type="password"
          placeholder="Passwort"
          required
          className={styles.input}
        />
        <input
          type="password"
          placeholder="Neues Passwort"
          required
          onChange={(e) => {
            setNewPassword(e.target.value);
            setValidPwd(PWD_REGEX.test(e.target.value));
          }}
          className={styles.input}
        />
        <p
          className={!validPwd ? styles.instructions : styles.offscreen}
        >
          8-24 Zeichen, mindestens einen Groß- und Kleinbuchstabe, eine
          Zahl und ein Sonderzeichen (!@#$%_-)
        </p>
        <input
          type="password"
          placeholder="Neues Passwort bestätigen"
          required
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          className={styles.input}
        />
        <button type="submit" className={styles.submitBtn}>Passwort ändern</button>
      </form>
      <br />
      <h3>Account löschen</h3>
      <form onSubmit={handleDeleteAccount}> {/* Wrap the input and button in a form */}
        <input
          type="password"
          placeholder="Passwort zum Löschen des Accounts"
          required
          onChange={(e) => setDeletePassword(e.target.value)} // Update state variable on change
          className={styles.input}
        />
        <button type="submit" className={styles.deleteBtn}>Account löschen</button>
      </form>
    </div>
    </>
  );
}

export default Account;