import styles from "./styles/CalenderDay.module.css";
import { useEffect, useContext } from "react";
import AuthContext from "../context/AuthProvider";

export default function CalenderDay({ onClose, date, events, addParticipant, removeParticipant }) {
  const { auth } = useContext(AuthContext);
  const id = auth.id;

  const day = () => {
    let parts = date.split(".");
    return parts[0];
  };

  const dayString = () => {
    switch (correctDate().getDay()) {
      case 0:
        return "Sonntag";
      case 1:
        return "Montag";
      case 2:
        return "Dienstag";
      case 3:
        return "Mittwoch";
      case 4:
        return "Donnerstag";
      case 5:
        return "Freitag";
      case 6:
        return "Samstag";
      default:
        return "Fehler";
    }
  };

  const correctDate = () => {
    let parts = date.split(".");
    let newDate = new Date(parts[2], parts[1] - 1, parts[0]);
    return newDate;
  };

  const filterEvents = () => {
    let filteredEvents = [];

    events.forEach((item) => {
      let correctedDate = new Date(item.date);
      if (correctedDate.getDate() === correctDate().getDate()) {
        filteredEvents.push(item);
      }
    });
    return filteredEvents;
  };

  const isParticipating = (event) => {
    let participating = false;
    event.participants.forEach((item) => {
      if (item === `${id}`) {
        participating = true;
      }
    });
    return participating;
  };

  useEffect(() => {
    // Fetch data or update state when dependencies change
    // Example: fetchEvents();
  }, [date, events]); // Include relevant dependencies



  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <h1>{day()}</h1>
          <h2>{dayString()}</h2>
        </div>
        <div className={styles.dottedLine} />
        <div className={styles.events}>
          {filterEvents().map((item, index) => {
            return (
              <div className={styles.event} key={index}>
                <div className={styles.eventLeft}>
                  <h3>{item.title}</h3>
                  <p className={styles.uhrzeit}>{item.time} Uhr</p>
                  <p className={styles.dauer}>Dauer: {item.duration}</p>
                  <p className={styles.dauer}>Infos: {item.infos}</p>
                  <p className={styles.dauer}>
                    Plätze: {item.takenSeats}/{item.totalSeats}
                  </p>
                </div>
                <div className={styles.eventRight}>
                  <div className={styles.eventRight}>
                    {item.takenSeats < item.totalSeats &&
                      !isParticipating(item) && (
                        <button className={`${styles.button} ${styles.buttonAnmelden}`} onClick={() => {addParticipant(item, id)}}>
                          Anmelden
                        </button>
                      )}
                    {isParticipating(item) && (
                      <button className={`${styles.button} ${styles.buttonAbmelden}`} onClick={() => {removeParticipant(item, id)}}>
                        Verlassen
                      </button>
                    )}
                    {item.takenSeats >= item.totalSeats &&
                      !isParticipating(item) && (
                        <button className={styles.button} disabled>
                          Kein Platz mehr frei
                        </button>
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
