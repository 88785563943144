import { Link } from 'react-router-dom'
import styles from './styles/Event.module.css'

export default function Footer(props) {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{props.title}</h1>
            <p className={styles.date}>{props.date}</p>
            <p className={styles.time}>{props.time}</p>
            <p className={styles.seats}> Plätze: {props.takenSeats}/{props.totalSeats}</p>
            <Link className={styles.link} to={`../events/${props.id}`}>
                Mehr Infos
            </Link>
        </div>
    )
}