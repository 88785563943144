import { useState, useEffect } from 'react';
import styles from "./styles/Gallery.module.css"
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import { Helmet } from "react-helmet";

function Gallery() {
  const [pictures, setPictures] = useState([]);
  const [lightboxDisplay, setLightBoxDisplay] = useState(false)
  const [imageToShow, setImageToShow] = useState('')
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const importPictures = async () => {
      const context = require.context('../pictures/gallery', false, /\.(png|jpe?g|webp|avif)$/);
      const files = context.keys();
      const pictures = await Promise.all(files.map(async (file) => {
        const image = await import(`../pictures/gallery/${file.slice(2)}`);
        return {
          src: image.default,
          alt: file.slice(2, -4),
        };
      }));
      setPictures(pictures);
    };
    importPictures();
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
};

const hideLightBox = () => {
  setLightBoxDisplay(false)
}

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <>
    <Helmet>
        <title>Galerie | Cold Creek Ranch</title>
        <meta name="description" content="Cold Creek Ranch - Galerie!" />
    </Helmet>
    <div className={styles.galleryElements}>
    <ResponsiveMasonry columnsCountBreakPoints={{300: 1, 750: 2, 900: 3, 1400: 4, 1900: 5, 2100: 6}}>
      <Masonry gutter='1rem'>
      {pictures.map((picture) => ( 
        <img key={picture.src} className={styles.element} src={picture.src} alt="" onClick={() => {
          if (windowSize[0] > 750) {
          showImage(picture.src)} 
        }
        }/>
      ))}
      </Masonry>
    </ResponsiveMasonry>
    </div>
      { lightboxDisplay ?
      <div id={styles.lightbox} onClick={hideLightBox}>
        <svg className={styles.lightbox_cross} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">{/* Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}<path fill="#ffffff" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
        <img id={styles.lightbox_img} src={imageToShow} alt="" onClick={hideLightBox}></img>
      </div>
    : '' }
    </>
  );
}

export default Gallery;
