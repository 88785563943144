import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import AuthContext from "../../context/AuthProvider";
import styles from "../styles/EventsId.module.css";

function EventsId() {
    const { auth } = useContext(AuthContext);
    const email = auth.email;
    const pwd = auth.pwd;
    const id = useLocation().pathname.split("/")[2];
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState({});
    const [participantNames, setParticipantNames] = useState([]);

    useEffect(() => {
        axios.get(`/api/events/eventsId/`, {headers: {id}})
            .then((res) => {
                setIsLoading(false);
                setEvents(res.data);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setEvents(null); // set events to null if there's an error
            });
    }, [id]);

    useEffect(() => {
        if (events && events.participants && events.participants.length !== 0) {
            const promises = events.participants.map((participantId) =>
                axios.get(`/api/user/user/`, {headers: { "email": email, "pwd": pwd, id: participantId }}, { withCredentials: true })
                    .then((res) => res.data.fullName)
                    .catch((err) => {
                        console.log(err);
                        return "Error loading participant";
                    })
            );
    
            Promise.all(promises).then((names) => {
                setParticipantNames(names);
            });
        }
    }, [events, email, pwd]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.eventContainer}>
            {isLoading ? (
                <div className={styles.loading}>
                    <h1>Lade Events...</h1>
                </div>
            ) : events ? (
                <div className={styles.eventDetails}>
                    <h1 className={styles.eventTitle}>{events.title}</h1>
                    <div className={styles.eventInfo}>
                        <h2>Datum:</h2> <span>{new Date(events.date).toLocaleDateString()}</span>
                    </div>
                    <div className={styles.eventInfo}>
                        <h2>Uhrzeit:</h2> <span>{events.time} Uhr</span>
                    </div>
                    <div className={styles.eventInfo}>
                        <h2>Dauer:</h2> <span>{events.duration}</span>
                    </div>
                    <div className={styles.eventInfo}>
                        <h2>Teilnehmer Anzahl:</h2> <span>{events.takenSeats}</span>
                    </div>
                    <div className={styles.eventInfo}>
                        <h2>Max. Teilnehmer:</h2> <span>{events.totalSeats}</span>
                    </div>
                    <div className={styles.eventInfo}>
                        <h2>Infos:</h2> <span>{events.infos}</span>
                    </div>
                    <div className={styles.participants}>
                        <h2>Teilnehmer:</h2>
                        {participantNames.length === 0 ? <p>Keine Teilnehmer</p> : participantNames.map((name, index) => <p key={index}>{name}</p>)}
                    </div>
                </div>
            ) : (
                <div>
                    <h1>Event nicht gefunden</h1>
                </div>
            )}
        </div>
    );
}

export default EventsId;