import React from "react";
import styles from "./styles/Homelinks.module.css";

function Homelinks({linkinfo}) {
  return (
    <div className={styles.homelinks}>
      <img className={styles.img} src={linkinfo.img} alt={linkinfo.span}/>
      <span>{linkinfo.span}</span>
    </div>
  );
}

export default Homelinks;