import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Register.module.css";
import axios from "axios";

const MAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{1,12}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_-]).{8,24}$/;

function Register() {
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [email, setEmail] = useState("");
  const [validMail, setValidMail] = useState(false);
  const [mailFocus, setMailFocus] = useState(false);
  const [challengeCode, setChallengeCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  useEffect(() => {
    setValidMail(MAIL_REGEX.test(email));
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = PWD_REGEX.test(pwd);
    const v2 = pwd === matchPwd;
    const v3 = MAIL_REGEX.test(email);
    const v4 = user.length > 0;
    const v5 = challengeCode.length > 0;
    if (!v1 || !v2 || !v3 || !v4 || !v5) {
      setErrMsg("Bitte alle Felder ausfüllen!");
      return;
    }
    const newUser = {
      user: user,
      pwd: pwd,
      email: email,
      code: challengeCode,
    };
    axios
      .post("/api/auth/register", newUser)
      .then((res) => {
        if (res.data === "User hinzugefügt!") {
          setSuccess(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message ) { 
          setErrMsg("Error: " + err.response.data.message);
        } else if (!err?.response) {
            setErrMsg("Server nicht erreichbar!");
        } else if (err.response?.status === 400) {
            setErrMsg("Fehlerhafte Anfrage!");
        } else if (err.response?.status === 900) {
            setErrMsg("Challange Code stimmt nicht!");
        } else if (err.response?.status === 401) {
            setErrMsg("Nicht autorisiert!");
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {success ? (
        <section>
          <h1>Registrierung erfolgreich!</h1>
          <p>
            <Link className="line" to="/login">
              Hier einloggen
            </Link>
          </p>
        </section>
      ) : (
        <div className="regContainer">
          <h1>Registrieren</h1>
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>
            {errMsg}
          </p>

          <form onSubmit={handleSubmit}>
            <div className="inputContainer">
              <input
                type="text"
                id="fullName"
                onChange={(e) => setUser(e.target.value)}
                required
                placeholder="Vollständiger Name:"
                autoComplete="name"
              />
            </div>

            <div className="inputContainer">
              <input
                type="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="E-Mail:"
                onFocus={() => setMailFocus(true)}
                onBlur={() => setMailFocus(false)}
                autoComplete="email"
              />
              <p
                id="mailnote"
                className={
                  mailFocus && !validMail ? "instructions" : "offscreen"
                }
              >
                Gib eine gültige E-Mail Adresse ein!
              </p>
            </div>

            <div className="inputContainer">
              <input
                type="password"
                id="password"
                required
                onChange={(e) => setPwd(e.target.value)}
                minLength={8}
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                placeholder="Passwort:"
                autoComplete="new-password"
              />
              <p
                id="pwdnote"
                className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
              >
                8-24 Zeichen, mindestens einen Groß- und Kleinbuchstabe, eine
                Zahl und ein Sonderzeichen (!@#$%_-)
              </p>
            </div>

            <div className="inputContainer">
              <input
                type="password"
                id="matchPassword"
                required
                onChange={(e) => setMatchPwd(e.target.value)}
                minLength={8}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                placeholder="Passwort wiederholen:"
                autoComplete="new-password"
              />
              <p
                id="matchnote"
                className={
                  matchFocus && !validMatch ? "instructions" : "offscreen"
                }
              >
                Passwort muss mit dem Ersten übereinstimmen!
              </p>
            </div>

            <div className="inputContainer">
              <input
                type="text"
                id="challengeCode"
                onChange={(e) => setChallengeCode(e.target.value)}
                required
                placeholder="Challenge Code:"
                autoComplete="one-time-code"
              />
            </div>

            <div className="inputContainer">
              <p>Mit dem Registrieren akzeptierst du unsere <Link to="/impressum" className={styles.datenschutzLink}>Datenschutzbestimmungen</Link></p>
              <button
                className="submitBtn"
                disabled={
                  !validPwd ||
                  !validMail ||
                  !validMatch ||
                  user.length === 0 ||
                  challengeCode.length === 0
                    ? true
                    : false
                }
              >
                Registrieren
              </button>
            </div>
          </form>
          <p>
            Schon registriert?
            <br />
            <Link className="line" to="/login">
              Hier einloggen
            </Link>
          </p>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default Register;
