import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles/Cookiebox.module.css";

export default function Cookiebox({ setCookieConsent }) {
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [hideCookieBanner, setHideCookieBanner] = useState(true);

  const setCookie = () => {
    document.cookie = "cookieConsent=true; max-age=" + 60 * 60 * 24 * 30;

    if (!document.cookie) {
      alert(
        "Cookie kann nicht gesetzt werden. Bitte aktivieren Sie Cookies in Ihrem Browser / auf dieser Website."
      );
    } else {
      setShowCookieBanner(false);
      setCookieConsent(true);
      setHideCookieBanner(true);
    }
  };

  useEffect(() => {
    setCookieConsent(false);
    if (!document.cookie) {
      setShowCookieBanner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!hideCookieBanner ? (
        <div className={`${styles.openBtnContainer} ${hideCookieBanner ? styles.hide : ""}`} onClick={() => {
          setHideCookieBanner(true);
          setShowCookieBanner(true);
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            {/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
            <path d="M247.2 17c-22.1-3.1-44.6 .9-64.4 11.4l-74 39.5C89.1 78.4 73.2 94.9 63.4 115L26.7 190.6c-9.8 20.1-13 42.9-9.1 64.9l14.5 82.8c3.9 22.1 14.6 42.3 30.7 57.9l60.3 58.4c16.1 15.6 36.6 25.6 58.7 28.7l83 11.7c22.1 3.1 44.6-.9 64.4-11.4l74-39.5c19.7-10.5 35.6-27 45.4-47.2l36.7-75.5c9.8-20.1 13-42.9 9.1-64.9l-14.6-82.8c-3.9-22.1-14.6-42.3-30.7-57.9L388.9 57.5c-16.1-15.6-36.6-25.6-58.7-28.7L247.2 17zM208 144a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM144 336a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm224-64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
          </svg>
        </div>
      ) : (
        <></>
      )}
      {showCookieBanner ? (
        <div className={styles.cookiebox}>
          <h2>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              {/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
              <path d="M247.2 17c-22.1-3.1-44.6 .9-64.4 11.4l-74 39.5C89.1 78.4 73.2 94.9 63.4 115L26.7 190.6c-9.8 20.1-13 42.9-9.1 64.9l14.5 82.8c3.9 22.1 14.6 42.3 30.7 57.9l60.3 58.4c16.1 15.6 36.6 25.6 58.7 28.7l83 11.7c22.1 3.1 44.6-.9 64.4-11.4l74-39.5c19.7-10.5 35.6-27 45.4-47.2l36.7-75.5c9.8-20.1 13-42.9 9.1-64.9l-14.6-82.8c-3.9-22.1-14.6-42.3-30.7-57.9L388.9 57.5c-16.1-15.6-36.6-25.6-58.7-28.7L247.2 17zM208 144a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM144 336a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm224-64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
            </svg>
            Einen Keks?
          </h2>
          <p>
            Diese Website nutzt Cookies um bestmögliche Funktionalität bieten zu
            können.
          </p>
          <Link className={styles.cookieLink} to="/impressum">
              Mehr ehrfahren
            </Link>
          <div className={styles.cookiebox_links}>
            <button
              className={styles.cookieBtn}
              onClick={() => {
                setCookie();
              }}
            >
              Verstanden!
            </button>
            <button
              className={styles.cookieBtnDecline}
              onClick={() => {
                setHideCookieBanner(false);
                setShowCookieBanner(false);
              }}
            >
              Ablehnen
            </button>

          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
