import React from "react";
import "./styles/Infocard.css";

function Inforcard({ info }) {
    return (
        <div className="infocard">
            <div className="svg">
                {info.svg}
            </div>
            <div className="title">
                <h1>{info.title}</h1>
            </div>
            {info.image &&
                <div className="image">
                    <img src={info.image} alt={info.title} />
                </div>
            }
            <div className="text">
                {info.text}
            </div>
        </div>
    )
}

export default Inforcard;