import { useEffect, useRef } from "react";
import Horses from "../Components/Horses"
import Infocard from "../Components/Infocard";
import styles from "./styles/Training.module.css"
import { Helmet } from "react-helmet";

const training = {
  svg: <svg xmlns="http://www.w3.org/2000/svg" className={styles.svg} viewBox="0 0 576 512">{/*Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.*/}<path d="M448 238.1V160h16l9.8 19.6c12.5 25.1 42.2 36.4 68.3 26c20.5-8.2 33.9-28 33.9-50.1V80c0-19.1-8.4-36.3-21.7-48H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H480 448C377.3 0 320 57.3 320 128H224 203.2 148.8c-30.7 0-57.6 16.3-72.5 40.8C33.2 174.5 0 211.4 0 256v56c0 13.3 10.7 24 24 24s24-10.7 24-24V256c0-13.4 6.6-25.2 16.7-32.5c1.6 13 6.3 25.4 13.6 36.4l28.2 42.4c8.3 12.4 6.4 28.7-1.2 41.6c-16.5 28-20.6 62.2-10 93.9l17.5 52.4c4.4 13.1 16.6 21.9 30.4 21.9h33.7c21.8 0 37.3-21.4 30.4-42.1l-20.8-62.5c-2.1-6.4-.5-13.4 4.3-18.2l12.7-12.7c13.2-13.2 20.6-31.1 20.6-49.7c0-2.3-.1-4.6-.3-6.9l84 24c4.1 1.2 8.2 2.1 12.3 2.8V480c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V315.7c19.2-19.2 31.5-45.7 32-75.7h0v-1.9zM496 96c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16z"/></svg>,
  title: "Training & Ausritte",
  text: <>
  <h3 className={styles.unter_unterschrift}>Training:</h3>
  <p>Wir trainieren die Disziplinen Reining, Pleasure, Trail und Ranch Riding.<br/>Dabei bieten wir unsere Trainings als Einzel- und Gruppenunterricht für Einsteller als auch auf unseren ausgebildeten Quarterhorses an.
  </p>
  <br/>
  <h3 className={styles.unter_unterschrift}>Ausritte:</h3>
  <p>Du möchtest nicht immer trainieren?<br/> Wir bieten Ausritte zwischen einer und drei Stunden. Hier kannst du die schöne Natur des Salzburgerlandes vom Pferderücken aus genießen.</p>
  </>
}

const kurse = {
  svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">{/*Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}<path d="M249.6 471.5c10.8 3.8 22.4-4.1 22.4-15.5V78.6c0-4.2-1.6-8.4-5-11C247.4 52 202.4 32 144 32C93.5 32 46.3 45.3 18.1 56.1C6.8 60.5 0 71.7 0 83.8V454.1c0 11.9 12.8 20.2 24.1 16.5C55.6 460.1 105.5 448 144 448c33.9 0 79 14 105.6 23.5zm76.8 0C353 462 398.1 448 432 448c38.5 0 88.4 12.1 119.9 22.6c11.3 3.8 24.1-4.6 24.1-16.5V83.8c0-12.1-6.8-23.3-18.1-27.6C529.7 45.3 482.5 32 432 32c-58.4 0-103.4 20-123 35.6c-3.3 2.6-5 6.8-5 11V456c0 11.4 11.7 19.3 22.4 15.5z"/></svg>,
  title: "Kurse",
  text: <>
  <h3 className={styles.unter_unterschrift}>Trail:</h3>
  <p>Unser Trailkurs beinhaltet alles was du können musst um am nächsten Turnier starten zu können. Ob Seitpass, das Tor oder ein rückwerts L, hier lernst du den Trail in Theorie und in Praxis.</p><br/>
  <h3 className={styles.unter_unterschrift}>Reining:</h3>
  <p>Rollbacks, Spins und Rundowns. Bei unserem Reiningkurs erfährst du in der Theorie und praktisch alles was es zu der schnellen Diziplin was es zu wissen gibt.</p><br/>
  <h3 className={styles.unter_unterschrift}>Pleasure:</h3>
  <p>Du möchtest Schritt, Trab, Gallopp mit schönen Übergängen können, oder deine Haltung und den feinen Umgang mit dem Pferd erlernen? Vielleicht ist unser Pleasurekurs etwas für dich. Wir bringen dir alles über die eleganten Disziplin bei.</p><br/>
  <h3 className={styles.unter_unterschrift}>Bodenarbeit:</h3>
  <p>Bodenarbeit is extrem wichtig. Daher bieten wir einen passenden Kurs an. Von der Theorie bis zur praktischen Ausführung eines Reitelements vom Boden aus, hier lernst du eine der wichtigsten Trainingsmethoden für Pferde.</p>
  </>
}

const wrc = {
  svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">{/*Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.*/}<path d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z"/></svg>,
  title: "WRC & Turniere",
  text: <><h3 className={styles.unter_unterschrift}>WRC:</h3>
  <p>Wir bieten einmal im Jahr die Möglichkeit das Western Riding Certificate (WRC) zu absolvieren. Dabei bieten wir jedem der möchte und vortgeschritten genug ist, die Möglichkeit teilzunehmen. Die Anmeldungen erfolgen im Frühjahr und die Prüfung anfang Herbst.</p> <br/>
  <br/>
  <h3 className={styles.unter_unterschrift}>Turnierbegleitung:</h3>
  <p>Neben dem WRC begleiten wir dich auch gerne auf Tuniere, egal ob mit deinem eigenen Pferd oder einem von uns. Deinem Tunierstart steht dann nichts mehr im Wege.</p>
  </>
}

const horse1 = {
  image: "/pictures/site/anna.webp",
  title: "Flame"
}

const horse2 = {
  image: "/pictures/site/anna.webp",
  title: "Gin"
}

const horse3 = {
  image: "/pictures/site/anna.webp",
  title: "Mango"
}

const horse4 = {
  image: "/pictures/site/anna.webp",
  title: "Ice"
}

const horse5 = {
  image: "/pictures/site/anna.webp",
  title: "Gunner"
}

const horse6 = {
  image: "/pictures/site/anna.webp",
  title: "Jackpot"
}

function Training() {

  const afterHeader = useRef(null);

  const handleScroll = () => {
    if (afterHeader.current) {
      afterHeader.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
    <title>Training | Cold Creek Ranch</title>
    <meta name="description" content="Cold Creek Ranch - Informationen zu den Trainingsmöglichkeiten und Kursen!" />
    </Helmet>
    <div className={styles.header}>
            <h1>Training</h1>
            <img src="/pictures/site/training.webp"/>
            <svg xmlns="http://www.w3.org/2000/svg" className={styles.scrollDownSVG} viewBox="0 0 384 512"
                onClick={() => {
                    handleScroll();
                }}
            >{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}<path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
        </div>
    <hr ref={afterHeader}/>
    <div className="training-kurse">
      <Infocard info={training}/>
      <Infocard info={kurse}/>
    </div>
    <div className="wrc-turnier">
      <Infocard id="wrc-info" info={wrc}/>
    </div>
    {/*<hr/>
    <h1 className={`${styles.text_horses} ${styles.train_heading}`}>Unsere Pferde:</h1>
    <div className={styles.horses}>
      <Horses info={horse1}/>
      <Horses info={horse2}/>
      <Horses info={horse3}/>
      <Horses info={horse4}/>
      <Horses info={horse5}/>
      <Horses info={horse6}/>
    </div> */}
    
    </>
  );
}

export default Training;