import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import styles from "./styles/Contact.module.css";

function Contact() {

    const afterHeader = useRef(null);

    const handleScroll = () => {
      if (afterHeader.current) {
        afterHeader.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
        <Helmet>
            <title>Kontakt | Cold Creek Ranch</title>
            <meta name="description" content="Cold Creek Ranch - Kontakt!" />
        </Helmet>
        <div className={styles.header}>
            <h1>Kontakt</h1>
            <img src="/pictures/site/contact.webp" alt=""/>
            <svg xmlns="http://www.w3.org/2000/svg" className={styles.scrollDownSVG} viewBox="0 0 384 512"
                onClick={() => {
                    handleScroll();
                }}
            >{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}<path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
        </div>
        <hr/>
        <div className={styles.content} ref={afterHeader}>
            <div className={styles.mail}>
            <svg className={styles.mailSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">{/*<!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->*/}<path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>
                <p className={styles.mailParagraph} id={styles['pMail']}>
                    E-Mail:<br/>
                    <a className={styles.mailAnchor} href="mailto:info@coldcreekranch.at">info@coldcreekranch.at</a>
                </p>
            </div>
        </div>

        </>
    );
}

export default Contact;