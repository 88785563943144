import styles from "./styles/Calender.module.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import CalenderDay from "./CalenderDay";

export default function Calender() {
  let weeks = [];

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [events, setEvents] = useState([]);
  const [showCalenderDay, setShowCalenderDay] = useState(false);
  const [calenderDayEvent, setCalenderDayEvent] = useState(null);

  const monthString = () => {
    switch (month) {
      case 0:
        return "Jänner";
      case 1:
        return "Februar";
      case 2:
        return "März";
      case 3:
        return "April";
      case 4:
        return "Mai";
      case 5:
        return "Juni";
      case 6:
        return "Juli";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "Oktober";
      case 10:
        return "November";
      case 11:
        return "Dezember";
      default:
        return "Fehler";
    }
  };

  const displayDays = () => {
    let days = [];

    const firstDay = new Date(year, month, 1).getDay();
    const adjustedFirstDay = (firstDay + 6) % 7;

    for (let i = 0; i < adjustedFirstDay; i++) {
      days.push(<div className={styles.day} key={i + ".empty"}></div>);
    }

    for (let i = 1; i <= new Date(year, month + 1, 0).getDate(); i++) {
      days.push(
        <div
          className={`${styles.day} ${styles.notEvent}`}
          key={i + "." + (month + 1) + "." + year}
          id={i + "." + (month + 1) + "." + year}
          onClick={(e) => {
            if (e.currentTarget.className.includes(styles.event)) {
              setCalenderDay(e.currentTarget.id);
            }
          }}
        >
          <div className={styles.dayNumber}>{i}</div>
          <div className={styles.isEvent}>Event</div>
        </div>
      );
    }

    // Group them into weeks

    let week = [];
    for (let i = 0; i < days.length; i++) {
      week.push(days[i]);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    }

    const tempNum = 7 - week.length;
    if (tempNum === 7) {
      return;
    }

    for (let i = 0; i < tempNum; i++) {
      week.push(<div className={styles.day} key={i + ".empty2"}></div>);
    }

    weeks.push(week);
  };

  const nextMonth = async () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
    displayDays();
  };

  const prevMonth = async () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
    displayDays();
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get("/api/events/events");
      let tempEvents = response.data.filter(
        (item) => new Date(item.date).getMonth() === latestMonth.current
      );
      setEvents(tempEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const displayEvents = (events) => {
    const newClassName = `${styles.day} ${styles.event}`;

    for (let i = 0; i < events.length; i++) {
      const date = new Date(events[i].date);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const event = document.getElementById(day + "." + month + "." + year);
      if (event) {
        event.className = newClassName;
      }
    }
  };

  const setCalenderDay = (dayInfo) => {
    if (showCalenderDay) {
      setShowCalenderDay(false);
      setCalenderDayEvent(null);
    } else if (!showCalenderDay) {
      setShowCalenderDay(true);
      setCalenderDayEvent(dayInfo);
    }
  };

  const addParticipant = async (event, id) => {
    try {
      // Update UI
      const updatedEvents = events.map((e) => {
        if (e._id === event._id) {
          return {
            ...e,
            participants: [...e.participants, `${id}`],
            takenSeats: e.takenSeats + 1,
          };
        }
        return e;
      });
      setEvents(updatedEvents);

      await axios.post("/api/events/eventsUpdate", {
        eventId: event._id,
        participants: [...event.participants, `${id}`],
        takenSeats: event.takenSeats + 1,
      });
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const removeParticipant = async (event, id) => {
    try {
      // Update UI
      const updatedEvents = events.map((e) => {
        if (e._id === event._id) {
          return {
            ...e,
            participants: e.participants.filter(
              (item) => item !== `${id}`
            ),
            takenSeats: e.takenSeats - 1,
          };
        }
        return e;
      });
      setEvents(updatedEvents);

      await axios.post("/api/events/eventsUpdate", {
        eventId: event._id,
        participants: event.participants.filter(
          (item) => item !== `${id}`
        ),
        takenSeats: event.takenSeats - 1,
      });
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const latestMonth = useRef(month);
  useEffect(() => {
    latestMonth.current = month;
    fetchEvents();
    // eslint-disable-next-line
  }, [month, year]);

  useEffect(() => {
    // Display events when events state changes
    latestMonth.current = month;
    displayEvents(events);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <div className={styles.calender}>
      <div className={styles.monthPicker}>
        <button
          className={styles.monthPickerButton}
          onClick={() => {
            prevMonth();
          }}
        >
          ◀
        </button>
        <h3 className={styles.monthPickerText}>
          {monthString()} {year}
        </h3>
        <button
          className={styles.monthPickerButton}
          onClick={() => {
            nextMonth();
          }}
        >
          ▶
        </button>
      </div>
      <div className={styles.calenderDay}>
        {showCalenderDay && (
          <CalenderDay
            onClose={setCalenderDay}
            date={calenderDayEvent}
            events={events}
            addParticipant={addParticipant}
            removeParticipant={removeParticipant}
          />
        )}
      </div>
      <div className={styles.dayNames}>
        <div className={styles.dayName}>Mo</div>
        <div className={styles.dayName}>Di</div>
        <div className={styles.dayName}>Mi</div>
        <div className={styles.dayName}>Do</div>
        <div className={styles.dayName}>Fr</div>
        <div className={styles.dayName}>Sa</div>
        <div className={styles.dayName}>So</div>
      </div>
      <div className={styles.weeks}>
        {displayDays()}
        <div className={styles.week}>{weeks[0]}</div>
        <div className={styles.week}>{weeks[1]}</div>
        <div className={styles.week}>{weeks[2]}</div>
        <div className={styles.week}>{weeks[3]}</div>
        <div className={styles.week}>{weeks[4]}</div>
        <div className={styles.week}>{weeks[5]}</div>
      </div>
    </div>
  );
}