import { useEffect } from "react";
import styles from "./styles/Home.module.css";
import { Link } from "react-router-dom";
import Homelinks from "../Components/Homelinks";
import { Helmet } from "react-helmet";

const training = {
  span: "Training",
  img: "/pictures/site/home-training.webp",
};

const about = {
  span: "Über uns",
  img: "/pictures/site/home-about.webp",
};

const gallery = {
  span: "Galerie",
  img: "/pictures/site/home-gallery.webp",
};

const contact = {
  span: "Kontakt",
  img: "/pictures/site/home-kontakt.webp",
};

function Home({ cookieConsent }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cold Creek Ranch</title>
        <meta
          name="description"
          content="Cold Creek Ranch - Westernreiten in Salzburg!"
        />
      </Helmet>
      <div className={styles.header}>
        <div className={styles.headerPC}>
        </div>
        <div className={styles.headerMobile}>

        </div>

      </div>
      <hr />
      <div className={styles.welcome_info}>
        <h3>KOMMEN SIE ZU UNS UND GENIESSEN SIE DAS LEBEN MIT DEN PFERDEN!</h3>
        <h1>Willkommen auf der Cold Creek Ranch!</h1>
      </div>
      <hr />
      <div className={styles.site_links}>
          <Link
            className={styles.linker}
            to="/training">
            <Homelinks linkinfo={training} />
          </Link>
          <Link className={styles.linker} to="/about">
            <Homelinks linkinfo={about} />
          </Link>
          <Link className={styles.linker} to="/gallery">
            <Homelinks linkinfo={gallery} />
          </Link>
          <Link className={styles.linker} to="/contact">
            <Homelinks linkinfo={contact} />
          </Link>
      </div>
      <hr />
      <div className={styles.map}>
        {cookieConsent || document.cookie ? (
          <iframe
            title="Map"
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJaQwSJSy9dkcRjjzmdqE3nuc&key=AIzaSyA1IcXnEx10UIj0yGKFCbGWChqQXd8zEm8&maptype=satellite&zoom=16"
          ></iframe>
        ) : (
          <div className={styles.blurredMap}>
            <h1>Um die Map anzuzeigen musst du Cookies akzeptieren!</h1>
          </div>
        )}
      </div>
      <hr />
    </>
  );
}

export default Home;
