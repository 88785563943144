import React from 'react'
import styles from './styles/Footer.module.css'
import { Link } from 'react-router-dom'

let year = new Date().getFullYear()

export default function Footer() {
  return (
    <div className={styles.footer}>
        <div className={styles.socialmedia}>
          <ul className={styles.sm_nav}>
            <li className={styles.insta}>
              <a href="https://www.instagram.com/coldcreekranchsalzburg/?hl=de" target="_blank" rel="noreferrer" className={styles.sm_link}>
                <svg viewBox="0 0 448 512" version="1.1" id="svg4" xmlns="http://www.w3.org/2000/svg">
                  <defs id="defs8" />
                  {/*<!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->*/}
                  <path
                    d="M 224,202.66 A 53.34,53.34 0 1 0 277.36,256 53.38,53.38 0 0 0 224,202.66 Z m 124.71,-41 A 54,54 0 0 0 318.3,131.25 c -21,-8.29 -71,-6.43 -94.3,-6.43 -23.3,0 -73.25,-1.93 -94.31,6.43 a 54,54 0 0 0 -30.41,30.41 c -8.28,21 -6.43,71.05 -6.43,94.33 0,23.28 -1.85,73.27 6.47,94.34 a 54,54 0 0 0 30.41,30.41 c 21,8.29 71,6.43 94.31,6.43 23.31,0 73.24,1.93 94.3,-6.43 a 54,54 0 0 0 30.41,-30.41 c 8.35,-21 6.43,-71.05 6.43,-94.33 0,-23.28 1.92,-73.26 -6.43,-94.33 z M 224,338 a 82,82 0 1 1 82,-82 81.9,81.9 0 0 1 -82,82 z m 85.38,-148.3 a 19.14,19.14 0 1 1 19.13,-19.14 19.1,19.1 0 0 1 -19.09,19.18 z M 400,32 H 48 A 48,48 0 0 0 0,80 v 352 a 48,48 0 0 0 48,48 h 352 a 48,48 0 0 0 48,-48 V 80 A 48,48 0 0 0 400,32 Z m -17.12,290 c -1.29,25.63 -7.14,48.34 -25.85,67 -18.71,18.66 -41.4,24.63 -67,25.85 -26.41,1.49 -105.59,1.49 -132,0 C 132.4,413.56 109.77,407.7 91.03,389 72.29,370.3 66.4,347.58 65.18,322 c -1.49,-26.42 -1.49,-105.61 0,-132 1.29,-25.63 7.07,-48.34 25.85,-67 18.78,-18.66 41.47,-24.56 67,-25.78 26.41,-1.49 105.59,-1.49 132,0 25.63,1.29 48.33,7.15 67,25.85 18.67,18.7 24.63,41.42 25.85,67.05 1.49,26.32 1.49,105.44 0,131.88 z"
                    id="path825" />
                </svg>
  
                <span className={styles.sm_text}>Instagram</span>
              </a>
            </li>
            <li className={styles.facebook}>
              <a href="https://www.facebook.com/coldcreekranch.faistenau/" target="_blank" rel="noreferrer" className={styles.sm_link}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  {/*<!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->*/}
                  <path
                    d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
                </svg>
                <span className={styles.sm_text}>Facebook</span>
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.links}>
          <Link className={styles.links_links} to="/">Start</Link>
          <Link className={styles.links_links} to="/training">Training</Link>
          <Link className={styles.links_links} to="/about">Über uns</Link>
          <Link className={styles.links_links} to="/gallery">Galerie</Link>
          <Link className={styles.links_links} to="/contact">Kontakt</Link>
        </div>
        <Link className={styles.impressum} to="/impressum">Impressum & Datenschutz</Link>
        <p className={styles.copy}>© {year} Cold Creek Ranch</p>
      </div>
  )
}