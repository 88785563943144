import { useRef, useEffect, useContext } from "react";
import styles from "./styles/Eventsender.module.css";
import axios from "axios";
import AuthContext from "../context/AuthProvider";


const Eventsender = ({ onEventCreated }) => {
  const eventNameRef = useRef("");
  const dateRef = useRef(new Date());
  const timeRef = useRef(new Date().getTime());
  const lengthRef = useRef("");
  const seatsRef = useRef();
  const infoRef = useRef("");

  const { auth } = useContext(AuthContext);
  const email = auth.email;
  const pwd = auth.pwd;

  const handleSubmit = (e) => {
    e.preventDefault();
    const title = eventNameRef.current.value;
    const date = dateRef.current.value;
    const time = timeRef.current.value;
    const duration = lengthRef.current.value;
    const totalSeats = parseInt(seatsRef.current.value);
    const takenSeats = 0;
    const infos = infoRef.current.value;
    const participants = [];

    const event = {
      title,
      date,
      time,
      duration,
      totalSeats,
      takenSeats,
      participants,
      infos,
    };

    axios.post("/api/events/eventAdd", event)
      .then(() => {
        document.getElementById("form").reset();
        if (onEventCreated) {
          onEventCreated(); // Call the passed callback function
        }
        console.log("Event created!");
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const renewChallengeCode = (e) => {
    e.preventDefault();
    axios
      .get("/api/challengeCode/challengeCode/", {headers: {email, pwd}})
      .then((res) => {
        const item = res.data[0];

        if (item !== undefined) {
          const id = item._id;
          return axios.delete(`/api/challengeCode/challengeCode/`, {headers: {email, pwd, id}});
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        axios
          .post("/api/challengeCode/challengeCode/", {email, pwd})
          .then(() => {
            fetchChallengeCode();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchChallengeCode = () => {
    axios
      .get("/api/challengeCode/challengeCode/", {headers: {email, pwd}})
      .then((res) => {
        document.getElementById("challengeCodeInput").value = res.data[0].code;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchChallengeCode();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <form id="form" onSubmit={handleSubmit}>
          <input
            className={styles.input}
            type="text"
            placeholder="Event Name"
            id="eventName"
            ref={eventNameRef}
            required={true}
          />
          <input
            className={styles.input}
            type="date"
            pattern="\d{2}\.\d{2}\.\d{4}"
            placeholder="dd.mm.yyyy"
            id="date"
            ref={dateRef}
            required={true}
            autoComplete="off"
          />

          <input
            className={styles.input}
            type="time"
            id="time"
            ref={timeRef}
            required={true}
            autoComplete="off"
          />
          <input
            className={styles.input}
            type="text"
            placeholder="Länge"
            id="length"
            ref={lengthRef}
            required={true}
            autoComplete="off"
          />
          <input
            className={styles.input}
            type="text"
            placeholder="Infos"
            id="infos"
            ref={infoRef}
            required={true}
            autoComplete="off"
          />
          <input
            className={styles.input}
            type="number"
            placeholder="Teilnehmeranzahl"
            id="seats"
            ref={seatsRef}
            required={true}
            autoComplete="off"
          />
          <button className={styles.submitButton} type="submit">
            Event erstellen
          </button>
        </form>
        <br />
        <input
          id="challengeCodeInput"
          type="text"
          placeholder="Challenge Code"
          readOnly
        />
        <button className={styles.submitButton} onClick={renewChallengeCode}>
          Neuen Code erstellen
        </button>
      </div>
    </>
  );
};

export default Eventsender;
