import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import "../styles/Login.css";



function Login() {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const mailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    mailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();


    const v1 = email.length > 0;
    const v2 = pwd.length > 0;

    if (!v1 || !v2) {
      setErrMsg("Bitte alle Felder ausfüllen!");
      return;
    }

    axios.post('/api/auth/loginUser', { email, pwd }, { withCredentials: true })
    .then((response) => {
      const fullName = response?.data?.fullName;
      const roles = response?.data?.roles;
      const id = response?.data?.id;
  
      setAuth({ fullName, pwd, roles, email, id });
      setEmail("");
      setPwd("");
      navigate(from, { replace: true });
    })
    .catch((err) => {
      if (!err?.response) {
        setErrMsg("Server nicht erreichbar!");
      } else if (err.response?.status === 400) {
        setErrMsg("Fehlerhafte Anfrage!");
      } else if (err.response?.status === 901) {
        setErrMsg("Ungültige Anmeldedaten!");
      } else if (err.response?.status === 401) {
        setErrMsg("User und Passwort stimmen nicht überein!");
      } else {
        setErrMsg("Login fehlgeschlagen!");
      }
      errRef.current.focus();
    });
  

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="regContainer">
        <h1>Einloggen</h1>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>
          {errMsg}
        </p>

        <form onSubmit={handleSubmit}>
          <div className="inputContainer">
            <input
              type="email"
              id="email"
              autoComplete="email"
              ref={mailRef}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="E-Mail:"
              value={email}
            />
          </div>

          <div className="inputContainer">
            <input
              type="password"
              id="password"
              required
              onChange={(e) => setPwd(e.target.value)}
              placeholder="Passwort:"
              value={pwd}
            />
          </div>

          <div className="inputContainer">
            <button className="submitBtn">Einloggen</button>
          </div>
        </form>
        <p>
          Noch keinen Account?
          <br />
          <Link className="line" to="/register">
            Hier registrieren
          </Link>
        </p>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default Login;
